import React, { useState, useEffect } from 'react';
import './services.css';

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  // Close modal when pressing the Escape key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setToggleState(0);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Close modal when clicking outside of it
  const handleOutsideClick = (event) => {
    if (event.target.classList.contains('services__modal')) {
      setToggleState(0);
    }
  };
  
  // Function to render bold text inside the description
  const renderTextWithBold = (text) => {
    // Split the text by <strong> or <b> tags
    const parts = text.split(/(<strong>.*?<\/strong>|<b>.*?<\/b>)/g);

    return parts.map((part, index) => {
      // If part contains bold tags, render it as a React component
      if (/<strong>.*?<\/strong>|<b>.*?<\/b>/.test(part)) {
        const isBold = part.includes('<strong>') || part.includes('<b>');
        const cleanText = part.replace(/<\/?strong>|<\/?b>/g, ''); // Clean the text from HTML tags
        return isBold ? <strong key={index}>{cleanText}</strong> : <b key={index}>{cleanText}</b>;
      }

      // Otherwise, just render plain text
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Szolgáltatásaim</h2>
      <span className="section__subtitle">Ti vagytok a fókuszban</span>

      <div className="services__container container grid">
        {[
          {
            id: 1,
            title: 'Lifestyle Portré',
            imgSrc: require('../../assets/portrait/portrait18.jpg'),
            description: `A fényképezés számomra mindig az emberről szólt – a valódi szépségről, a természetes pillanatokról.
                          Célom, hogy rólad is olyan képeket készítsek, amelyek egyszerre gyönyörűek és őszinték. A természetes fény varázsa különleges hangulatot ad a fotóknak, ezért elsősorban kültéren, napfénystúdióban vagy akár a saját munkahelyi környezetedben örökítem meg a legjobb pillanataidat.
                
                Az átadott képeket utómunkával ellátva adom át, online galérián keresztül. Az utómunka magába 
                foglalja a szín- és fénykorrekciót, vágások igazítását, valamint a bőr retusálást természetes mértékig.
                Nyers képek átadása nem lehetséges.
                
                Időtartam: 1 - 1.5 óra
                Helyszín: szabadtéren, napfénystúdióban, saját munkahelyen vagy otthonodban
                Ár:
                35.000 Ft – 10 db kép
                50.000 Ft – 20 db kép
                `
          },
          {
            id: 2,
            title: 'Esküvői fotózás',
            imgSrc: require('../../assets/wedding/MerciEsPisti/MerciEsPisti8.jpg'),
            description: `A pillanatok megörökítése a szenvedélyem – a szépséget meglátni, elkapni, és örökre megőrizni!
            Az esküvők különösen közel állnak a szívemhez, hiszen nincs szebb annál, mint látni két ember boldogságát, és életre szóló emlékké formálni minden apró mozzanatot. Nemcsak a nagy pillanatokat örökítem meg, hanem azokat is, amiket a Nagy Nap forgatagában talán észre sem vennétek. A galériátokban a megható esküvői események mellett gondosan elkészített részletfotók is helyet kapnak – a dekoráció, a helyszín és az apró, mégis fontos részletek, amelyek teljessé teszik a történeteteket.

            <strong>Csomagok</strong>

            --------------------------------------------------

            <strong>Mini Csomag</strong>
            Tartalma:
            - polgári és/vagy egyházi szertartás
            - csoportképek a vendégekkel
            - Rendelkezésre állás: maximum 2 óra
            - Ár: 90.000 Ft
            - Átadott képek száma: 100-150 db
            
            --------------------------------------------------

            <strong>Mini Csomag Extra</strong>
            Tartalma:
            - polgári és/vagy egyházi szertartás
            - csoportképek a vendégekkel
            - kreatív párfotózás aznap
            - Rendelkezésre állás: maximum 2 óra
            - Ár: 150.000 Ft
            - Átadott képek száma: 200-250 db

            --------------------------------------------------
            <strong>Klasszikus Csomag</strong>
            Tartalma:
            - polgári és/vagy egyházi szertartás
            - csoportképek a vendégekkel
            - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
            - kreatív párfotózás aznap vagy egy másik napon
            - Rendelkezésre állás: maximum 10 óra
            - jegyesfotózás 50% árkedvezménnyel
            - Ár: 290.000 Ft Ft
            - Átadott képek száma: minimum 500 db

            --------------------------------------------------
            <strong>Klasszikus Csomag Extra</strong>
            Tartalma:
            - kikérés / first look
            - polgári és/vagy egyházi szertartás
            - csoportképek a vendégekkel
            - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
            - kreatív párfotózás aznap vagy egy másik napon
            - Rendelkezésre állás: maximum 12 óra
            - jegyesfotózás 50% árkedvezménnyel
            - Ár: 310.000 Ft Ft
            - Átadott képek száma: minimum 600 db

            --------------------------------------------------

            <strong>Prémium Csomag</strong>
            Tartalma:
            - menyasszony és vőlegény készülődése
            - kikérés / first look
            - polgári és/vagy egyházi szertartás
            - csoportképek a vendégekkel
            - esküvői vacsora fotózása tortavágás vagy menyecsketánc végéig (max. hajnali 1 óráig)
            - kreatív párfotózás aznap vagy egy másik napon
            - Rendelkezésre állás: maximum 14 óra
            - ajándék jegyesfotózás
            - Ár: 350.000 Ft Ft
            - Átadott képek száma: minimum 700 db
            `
          }
          ,
          {
            id: 3,
            title: 'Pár- és jegyesfotózás',
            imgSrc: require('../../assets/couple/VanesszaEsIsti/VanesszaEsIsti21.jpg'),
            description: `Jegyesfotózás – A tökéletes felkészülés a Nagy Napra!

            A jegyesfotózás nem csak egy szép emlék, hanem egy fantasztikus lehetőség arra, hogy jobban megismerjük egymást, miközben egy igazán különleges pillanatról készítünk gyönyörű képeket. Azt a természetes, játékos hangulatot szeretném megragadni, ami csak rajtatok múlik – úgy, hogy közben nem érzitek magatokat idegenül a kamera előtt.

            Ez a fotózás segít abban is, hogy kényelmesebben érezzétek magatokat az esküvőn, hiszen már tudni fogjátok, mire számíthattok, és hogyan viselkedjetek a képek készítése közben. A jegyesfotózás lehetőséget ad arra, hogy egy igazán egyedi helyszínt válasszunk, amely tökéletesen tükrözi a kapcsolatotokat. Képzeljétek el ezt úgy, mint egy romantikus kirándulást vagy randevút, csak a fotós jelenlétével, aki örök emlékeket készít nektek.
          
            Külön is kérhető párfotózás, vagy a kreatív fotózás az esküvői szettben!
          
            - Időtartam:  1 - 2 óra
            - Helyszín: Általatok választott helyszín
            - Ár: 60.000 Ft (az ár a stúdió bérleti díját nem tartalmazza).
            - Átadott képek száma: 75-100 db

            `
          },
          {
            id: 4,
            title: 'Kismama fotózás',
            imgSrc: require('../../assets/family/family13.jpg'),
            description: `Ez a csomag azoknak a boldog pároknak szól, akik várják első gyermekük érkezését, és szeretnék megörökíteni ezt az életük legszebb időszakát. A kismama fotózás során különleges, érzelmekkel teli pillanatokat örökítünk meg, amelyek örök emlékek maradnak. A fő fókusz a mamán van, de ha a leendő apuka is szeretne részt venni a fotózáson, közös, intim képeket is készítünk, amelyek megmutatják a család legelső közös pillanatait.

            Ez a fotózás nem csak egy emlék, hanem egy lehetőség arra, hogy a várandósság csodálatos pillanatait örök életre megőrizzétek. A képek megmutatják a szeretetet, az izgalmat és az örömöt, amit a baba érkezése hoz, így mindig vissza tudtok emlékezni erre az időszakra, ami örökre szívetekben él majd.
            
            - Időtartam: 1-1.5 óra
            - Helyszín: szabadtéren, stúdióban vagy saját otthonotokban
            - Ár: 50.000 Ft
            - Átadott képek száma: 75-100 db
            
            `
          },
          {
            id: 5,
            title: 'Családi fotózás',
            imgSrc: require('../../assets/family/family106.jpg'),
            description: `Családi Fotózás – A Legszebb Pillanatok Örök Értékei
            ----------------------------------------------------------------------------------------
            <strong>Családi fotózás</strong>
            
            Ez a csomag azoknak szól, akik szeretnék megörökíteni családjuk legszebb pillanatait, és örök emlékeket szeretnének hagyni a gyermekeikkel közös pillanatokból. A fotózás során nem csupán a család egészét örökítem meg, hanem külön-külön is készítek képeket a testvérekről, portrékat a gyerekekről, hogy minden kis mosolyt, nevetést, és kedves pillanatot megőrizzünk.

            Ezen kívül különleges, meghitt pillanatokat is készítek a szülőkről – egy-egy romantikus képpel, ahol csak ketten vannak. Így a családi album nemcsak a gyerekekről, hanem a szülői kapcsolatról is szólni fog.

            A célom, hogy a fotózás során minden egyes pillanatot úgy örökítsek meg, ahogyan azok valóban történtek – természetesen, szeretettel, és örömmel. Ezek a képek egy életre szóló emlékeket adnak, amelyek generációkon át örökre megmaradnak.

            - Időtartam: 1-1,5 óra
            - Helyszín: szabadtéren, stúdióban vagy saját otthonotokban
            - Ár: 50.000 Ft (az ár a stúdió bérleti díját nem tartalmazza!).
            - Átadott képek száma: 75-100 db

            ----------------------------------------------------------------------------------------
            <strong>Családi ünnepi fotózás</strong>
          
            Nincs is szebb, mint a család jeles pillanatait megörökíteni, és most itt a lehetőség, hogy a legfontosabb eseményeket örökre emlékezetessé tegyük. Legyen szó egy születésnapi zsúrról, keresztelőről, ballagásról vagy bármilyen más családi ünnepről, segítek megörökíteni azokat a meghitt pillanatokat, amelyek évről évre újra és újra eszünkbe jutnak.

            A fotózás során nem csupán egy képsorozatot, hanem egy történetet alkotunk, amely minden apró érzelemről, mosolyról és nevetésről szól. Az örömteli pillanatok megörökítése mellett az is fontos számomra, hogy minden egyes fénykép tükrözze a családotok egyedi és szeretetteljes dinamizmusát.

            Ha van egy különleges esemény, amelyet szeretnétek megörökíttetni, írjátok meg nekem, hogy mire lenne szükségetek! Egyedi igényeitek alapján segítek megtervezni a fotózást, hogy az minden szempontból tökéletes legyen.
            
            ----------------------------------------------------------------------------------------
            <strong>"Egy napunk" csomag</strong>
          
            Az évek gyorsan elröppennek, és gyakran a mindennapi rohanásban elfelejtjük megörökíteni a legcukibb pillanatokat. Pedig egy-egy egyszerű, de különleges szituáció olyan értékes emlékeket adhat, amelyeket mindig szívesen előveszünk. Éppen ezért hoztam létre azt a lehetőséget, hogy egy egész napot együtt tölthessünk, és minden apró, varázslatos pillanatot megörökíthessünk.

            Képzeld el, hogy reggeltől estig a ti világotok részeként kaphatsz olyan képeket, amik valóban tükrözik az életeteket – a reggeli készülődéstől kezdve, a családi nevetésekig, egészen az esti meseolvasásig. A célom, hogy minden egyes pillanatról olyan képeket alkossak, amelyek a legszebb emlékekké válnak, és amelyeket a család minden tagja örömmel fog nézegetni, amikor évről évre előkerülnek az albumok.

            - Időtartam: ~10-12 óra
            - Helyszín: saját otthonotok, illetve ahova napközben mentek és elkísérhetlek titeket (pl. játszótér)
            - Ár: 180.000 Ft
            - Átadott képek száma: 350-400 db
            `
            }
        ].map((service) => (
          <div className="services__content" key={service.id}>
            <>
              <img
                src={service.imgSrc}
                alt={service.title}
                onClick={() => toggleTab(service.id)}
                className="services__img"
              />
              <h3 className="services__title" onClick={() => toggleTab(service.id)}>
                {service.title}
              </h3>
            </>

            {toggleState === service.id && (
              <div className="services__modal active-modal" onClick={handleOutsideClick}>
                <div className="services__modal-content">
                  <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                  <h3 className="services__modal-title">{service.title}</h3>
                  {service.description.split('\n').map((line, index) => (
                    <p key={index} className="services__modal-description">
                      {/* Render bold text inside the line */}
                      {renderTextWithBold(line)}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services
