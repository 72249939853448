import React from 'react'
import Send from '../icons/Send'
import "./home.css"

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">Vági Erika</h1>
      <h3 className="home__subtitle">fotográfus</h3>

      <p className="home__description">
      Esküvői fotósként a célom, hogy a legszebb pillanataitokat őszintén és természetesen örökítsem meg. A „lifestyle” stílusú fotózás során az erőltetett pózok helyett azokra az apró, de annál fontosabb érzelmekre és pillanatokra koncentrálok, amelyek valóban meghatározzák a napotokat. A képeim nem csak emlékek, hanem a kapcsolatotok valós érzéseit örökítik meg.

Ha olyan fotókat keresel, amelyek hűen tükrözik a boldogságotokat, örömmel várom, hogy megismerjelek titeket!      </p>
    <a href="#contact" className="button button--flex">
      Üzenj nekem
      <Send />
    </a>
    </div>
  )
}

export default Data
